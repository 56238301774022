import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { graphql } from 'gatsby'
import { Flex } from 'rebass'
import { useQueryParam, StringParam } from 'use-query-params'
import { useLazyQuery } from '@apollo/client'

import Layout from 'components/layout'
import SEO from 'components/seo'
import LottieShape from 'components/animations/LottieShape'
import TeamAnimation from 'components/animations/abstract/TeamAnimation'
import SignInUI from 'components/SignInUI'
import UserContext from 'context/user/UserContext'
import {
  CHECK_IF_MEETING_EXISTS,
  GET_USER_MEETING_DYNAMIC_LINK,
} from 'services/graphql'

const Invite = (props) => {
  const { t } = useTranslation('invite')
  const { user, isAuthInitialized } = useContext(UserContext)
  const [aud] = useQueryParam('aud', StringParam)
  const [
    getUserMeetingDynamicLink,
    {
      data: getUserMeetingDynamicLinkData,
      error: getUserMeetingDynamicLinkError,
    },
  ] = useLazyQuery(GET_USER_MEETING_DYNAMIC_LINK)
  const [
    checkIfMeetingExists,
    { data: checkIfMeetingExistsData, error: checkIfMeetingExistsError },
  ] = useLazyQuery(CHECK_IF_MEETING_EXISTS)
  const siteTitle = props.data.site.siteMetadata.title

  const isAudValid = aud !== '' && typeof aud !== 'undefined'
  const isInvalidMeeting =
    checkIfMeetingExistsData && !checkIfMeetingExistsData.checkIfMeetingExists
  const isQueryError =
    checkIfMeetingExistsError || getUserMeetingDynamicLinkError

  const UnauthorizedUserFragment = (
    <SignInUI heading={t('unauthorizedHeading')} signInSuccessUrl="" />
  )

  const MissingTokenAudienceQueryParameterFragment = (
    <h1>{t('missingQueryParamMessage')}</h1>
  )

  const ErrorFragment = <h1>{t('genericErrorMessage')}</h1>

  const AuthorizedUserFragment = (
    <>
      <h1>
        {t('greeting', {
          name: user && user.displayName ? user.displayName : null,
        })}
      </h1>
      <Flex flexWrap="wrap-reverse" alignContent="center" alignItems="center">
        <LottieShape animationData={TeamAnimation} size={325} />
        <h2>{t('joiningMeeting')}</h2>
      </Flex>
    </>
  )

  // check if the meeting exists on page load
  useEffect(() => {
    const fetchCheckIfMeetingExists = async () => {
      await checkIfMeetingExists({
        variables: { meetingId: aud },
      })
    }

    if (user && user.uid && isAudValid) {
      fetchCheckIfMeetingExists()
    }
    // eslint-disable-next-line
  }, [user, isAudValid])

  useEffect(() => {
    const fetchUserMeetingDynamicLink = async () => {
      await getUserMeetingDynamicLink({
        variables: {
          meetingId: aud,
        },
      })
    }

    if (
      isAuthInitialized &&
      user &&
      isAudValid &&
      checkIfMeetingExistsData &&
      checkIfMeetingExistsData.checkIfMeetingExists &&
      checkIfMeetingExistsData.checkIfMeetingExists.id
    ) {
      fetchUserMeetingDynamicLink()
    }
  }, [
    user,
    aud,
    isAuthInitialized,
    isAudValid,
    checkIfMeetingExistsData,
    getUserMeetingDynamicLink,
  ])

  useEffect(() => {
    if (
      getUserMeetingDynamicLinkData &&
      getUserMeetingDynamicLinkData.getUserMeetingDynamicLink &&
      typeof window !== 'undefined'
    ) {
      window.location.assign(
        getUserMeetingDynamicLinkData.getUserMeetingDynamicLink,
      )
    }
  }, [getUserMeetingDynamicLinkData])

  return (
    <Layout location={props.location} title={siteTitle}>
      <SEO title={t('title')} />
      {!isAuthInitialized ? <h3>{t('loading')}</h3> : null}
      {(!isAudValid || isInvalidMeeting) && isAuthInitialized
        ? MissingTokenAudienceQueryParameterFragment
        : null}
      {isAuthInitialized && !user && isAudValid
        ? UnauthorizedUserFragment
        : null}
      {isAuthInitialized &&
      user &&
      isAudValid &&
      !isInvalidMeeting &&
      !isQueryError
        ? AuthorizedUserFragment
        : null}
      {isQueryError && isAudValid ? ErrorFragment : null}
    </Layout>
  )
}

export default Invite

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
